import { useHWAppData } from "./HWAppDataProvider";

function NewContact() {

  const { HWData, setHWData } = useHWAppData();

  const update = (event) => {
    let temp  = HWData.newContact;    
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    temp[key] = val;
    setHWData({ ...HWData, newContact: temp });
  };

  const deliverableUpdate = (event) => {
    let temp = HWData.newContact; 
    temp['deliverable'] = event.target.checked;
    setHWData({ ...HWData, newContact: temp });
  };
  
  const cancel = (event) => {
    setHWData({ ...HWData, page: "home", edit: null, search: "" });
  };
  
  const saveNew = (event) => {
    event.preventDefault();
    
//     if (!HWData.newContact['email'].match(/\w@\w/)) {
//       alert('Please provide an email address');
//       return false; 
//     }
    
    // set screen to "laoding" gif
    delete HWData.combined;
    setHWData({ ...HWData, page: "home", search: "", loading: true});
    
    if (!(HWData.newContact['address_1'] || HWData.newContact['address_1'])
      && HWData.newContact['city'] && HWData.newContact['state'] && HWData.newContact['zip']) {
    
      HWData.newContact['deliverable'] = false;  
    }
    
    fetch( "https://aoufternk0.execute-api.us-east-1.amazonaws.com/0/members", {
      method : "POST",
      headers : {
        Authorization : HWData.token
      },
      body: JSON.stringify({
        email : HWData.userEmail,
        newContact : HWData.newContact
      })
    })
    .then((res) => {
      if (401 === res.status) {
        alert("You're authentication has expired. Please login again.");
        setHWData({page: "login", token: null, userEmail: "", userPassword : "", loading: false});
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          setHWData({
            ...HWData, 
            combined : [
              ...data.people,
              ...data.groups
            ],
            people : data.people,
            groups : data.groups, 
            current : [
              ...data.people,
              ...data.groups
            ],
            deliverableOnly : false,
            chosen : "combined",
            loading : false,
            page : "home",
            search: "",
            donations : data.donations
          }); 
        })
        .catch((err) => {
          console.log(err);
        })        
      }
    })
    .catch((err) => {
      console.log(err);
    })          
  };


  return (
    <>
      <div className="row">
        <div className="col d-md-flex justify-content-md-end d-grid gap-2">
          <button className="btn btn-outline-secondary btn-sm" onClick={cancel} type="button">
            Cancel
          </button>
          <button className="btn btn-outline-success btn-sm" onClick={saveNew} type="button">
            Save New Contact
          </button>
        </div>
      </div>   
    
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Name: 
        </div>    
        <div className="col-md-10">
          <input type="text" name="name" value={HWData.newContact['name']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Address:
        </div>
        <div className="col-md-10">
          <input type="text" name="address_1" value={HWData.newContact['address_1']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-10 offset-md-2">
          <input type="text" name="address_2" value={HWData.newContact['address_2']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          City:
        </div>
        <div className="col-md-10">
          <input type="text" name="city" value={HWData.newContact['city']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          State:
        </div>
        <div className="col-md-10">
          <input type="text" name="state" value={HWData.newContact['state']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Zip Code:
        </div>
        <div className="col-md-10">
          <input type="text" name="zip" value={HWData.newContact['zip']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Email:
        </div>
        <div className="col-md-10">
          <input type="text" name="email" value={HWData.newContact['email']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Phone:
        </div>
        <div className="col-md-10">
          <input type="text" name="phone" value={HWData.newContact['phone']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Type of Entry:
        </div>
        <div className="col-md-10">
          <input type="radio" name="type" value="person" onChange={update}
          checked={"person" === HWData.newContact['type']} 
          style={{"marginRight":"5px"}} /> Person
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-10 offset-md-2">
          <input type="radio" name="type" value="group" onChange={update}
          checked={"group" === HWData.newContact['type']}
          style={{"marginRight":"5px"}} /> Group
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Deliverable Address? 
        </div>
        <div className="col-md-10">
          <input type="checkbox" name="deliverable" value="1" onChange={deliverableUpdate}
          checked={HWData.newContact['deliverable']} />
        </div>
      </div>

      <div className="row"  style={{"marginTop":"10px","marginBottom":"50px"}}>
        <div className="col d-md-flex justify-content-md-end d-grid gap-2">
          <button className="btn btn-outline-secondary btn-sm" onClick={cancel} type="button">
            Cancel
          </button>
          <button className="btn btn-outline-success btn-sm" onClick={saveNew} type="button">
            Save New Contact
          </button>
        </div>
      </div>   
    </>    
  );

}

export default NewContact;