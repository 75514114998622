import { FixedSizeList as List } from 'react-window';
import { useHWAppData } from "./HWAppDataProvider";
import {v4} from "uuid";

const Row = ({ index, style }) => {
  const { HWData, setHWData } = useHWAppData();

  const rowData = (index) => {  
    return HWData.current[index]['name'];
  };
  
  const Address = (index) => {
    const address = HWData.current[index]['address_1']
                  + (HWData.current[index]['address_1'] ? ', ' : '') + HWData.current[index]['address_2']
                  + (HWData.current[index]['address_2'] ? ', ' : '') + HWData.current[index]['city']
                  + (HWData.current[index]['city'] ? ', ' : '') + HWData.current[index]['state'] + ' '
                  + HWData.current[index]['zip'];
                  
    return address;
  };
  
  const editEntry = (event) => {
    event.preventDefault();
    setHWData({ ...HWData, edit: event.target.dataset.index, page: "edit" });
  };
  
  const deleteContact = (event) => {
    event.preventDefault();
    if (window.confirm("Permanently delete " + HWData.current[event.target.dataset.index]['name'] + "?")) {
      setHWData({ ...HWData, page: "home", search: "", loading: true});
      
      fetch( "https://aoufternk0.execute-api.us-east-1.amazonaws.com/0/members", {
        method : "DELETE",
        headers : {
          Authorization : HWData.token
        },
        body: JSON.stringify({
          email : HWData.userEmail,
          id : HWData.current[event.target.dataset.index].id
        })
      })
      .then((res) => {
        if (401 === res.status) {
          alert("You're authentication has expired. Please login again.");
          setHWData({page: "login", token: null, userEmail: "", userPassword : "", loading: false});
        }
        else if (200 === res.status) {
          res.json().then((data) => {
            setHWData({
              ...HWData, 
              combined : [
                ...data.people,
                ...data.groups
              ],
              people : data.people,
              groups : data.groups, 
              current : [
                ...data.people,
                ...data.groups
              ],
              deliverableOnly : false,
              chosen : "combined",
              loading : false,
              page : "home",
              search: "",
              donations : data.donations
            }); 
          })
          .catch((err) => {
            console.log(err);
          })        
        }
      })
      .catch((err) => {
        console.log(err);
      });     
    }
  };
  
  const Email = (index) => {
    if (HWData.current[index]['email']) {
      return (
        <><a href={"mailto:" + HWData.current[index]['email']}>{HWData.current[index]['email']}</a><br /></>
      );
    }
    else {
      return "";
    }
  };

  const newDonationId = () => {
    const id   = v4();
    const used = Object.values(HWData.donations).reduce((prev, curr) => {
      if (prev) { return prev; }
      else {
        return curr.reduce((p, c) => (p || c.id === id), false);
      }
    }, false);
    
    if (used) {
      return newDonationId();
    }
    else {
      return id;
    }
  };
  
  const editDonations = (event) => {
    const user_id = HWData.current[event.target.dataset.index].id;
    
    let newDate = new Date();


    let withNew = [{
      id : newDonationId(),
      user_id : user_id,
      amount : "",
      timestamp : (newDate.getTime() / 1000)
    }];


    if (HWData.donations[user_id]) {     
      
      /* (remove any previously added empty new donation rows) */
      
      let currentSet = HWData.donations[user_id].reduce((prev, curr) => {
        if (!curr.amount) {
          return prev;
        }
        else {
          return [ ...prev, curr ];
        }
      }, []);
                
      withNew = [ ...currentSet, {
        id : newDonationId(),
        user_id : user_id,
        amount : "",
        timestamp : (newDate.getTime() / 1000)
      } ].reverse();
    }
    
    const donations = { ...HWData.donations, [user_id] : withNew };
      
    setHWData({ 
      ...HWData, 
      edit: event.target.dataset.index, 
      page: "donations", 
      donations : donations
    });
  };
  
  const Donation = (index) => {
    const user_id = HWData.current[index].id;
    
    if (HWData.donations[user_id]) {
      const latest = HWData.donations[user_id].reduce((prev, curr) => {
        return (curr.timestamp > prev.timestamp && parseFloat(curr.amount) > 0) ? curr : prev;
      }, {timestamp: 0});
      
      
      if (0.0 === parseFloat(latest.amount) || "" === latest.amount || "undefined" === typeof(latest.amount)) {
        return "";
      }
            
      const date = new Date(latest.timestamp * 1000);
      const display_date = (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear();
    
      return (
        <>
          Latest Donation: <br />
          ${latest.amount} on {display_date}
        </>
      );
    }
    else {
      return "";
    }
  };

  return (
    <div className={index % 2 ? 'ListItemOdd' : 'ListItemEven'} style={style}>
      <div className="row" style={{"width":"100%"}}>
        <div className="col-5">
          {rowData(index)}
          <br />
          {Address(index)} 
          <br />
          {Email(index)}
          {HWData.current[index]['phone']}
        </div>
        <div className="col-5">
          {Donation(index)}
        </div>
        <div className="col-2">
          <div className="d-md-flex justify-content-md-end d-grid gap-2" style={{"paddingRight":"20px"}}>
            <button className="btn btn-outline-danger btn-sm" onClick={deleteContact} data-index={index}>Delete</button>
            <button className="btn btn-outline-secondary btn-sm" onClick={editDonations} data-index={index}>Donations</button>
            <button className="btn btn-outline-primary btn-sm" onClick={editEntry} data-index={index}>Edit Contact</button>     
          </div>
        </div> 
      </div>
    </div>
  );
}; 


function MemberList() {

  const { HWData } = useHWAppData();
  
  const width = document.getElementById('innerHeading').offsetWidth;
  
  const height = window.innerHeight - document.getElementById('headerBar').offsetHeight - document.getElementById('innerHeading').offsetHeight - 20;

  return (
    <List
      height={height}
      itemCount={HWData.current.length}
      itemSize={120}
      width={width}
    >
      {Row}
    </List> 
  );
}

export default MemberList;