import React, { createContext, useState, useContext } from "react";

const HWAppData = createContext();
export const useHWAppData = () => useContext(HWAppData);

export default function HWAppDataProvider({children}) {
  const [HWData, setHWData] = useState({
    userEmail : "",
    userPassword : "",
    token : null,
    loading : false,
    page : "login",
    search : "",
    edit : null
  });
    
  return (
    <HWAppData.Provider value={{ HWData, setHWData }}>
      {children}
    </HWAppData.Provider>
  );
}