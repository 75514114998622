import './LoadingLogin.css';

function LoadingLogin() {

  return (
  <div className="text-center">   
    <main className="form-signin">
      <img src="/loading.gif" alt="loading..." />
    </main>
  </div>  
  );

}

export default LoadingLogin;