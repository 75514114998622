import './Home.css';
import { useHWAppData } from "./HWAppDataProvider";
import LoadingLogin from "./LoadingLogin";
import MemberList from "./MemberList";
import Edit from "./Edit";
import NewContact from "./NewContact";
import {v4} from "uuid";
import EditDonations from "./EditDonations";

function LoadingList() {
  const { HWData } = useHWAppData();
  
  if ("undefined" === typeof(HWData.combined) || !HWData.combined || HWData.loading) {
    return <LoadingLogin />;
  }
  else {
    return <MemberList />;
  }
}

function Focus() {
  const { HWData } = useHWAppData();
  
  switch(HWData.page) {
    case "home":
    case "people":
    case "groups":
      return <LoadingList />;
    case "edit":
      return <Edit />;
    case "newContact":
      return <NewContact />;
    case "donations":
      return <EditDonations />;
    default:
      return <LoadingList />;
  }
}

function Home() {

  const { HWData, setHWData } = useHWAppData();
  
  const screenData = () => HWData.current.reduce((prev, curr) => (curr.deliverable ? [...prev, curr] : prev), []);
  
  const screenSet = (dataSet) => dataSet.reduce((prev, curr) => (curr.deliverable ? [...prev, curr] : prev), []);

  const deliverable = () => {
    if (HWData.deliverableOnly) {
      setHWData({ ...HWData, deliverableOnly : false, current : HWData[HWData.chosen], search: "" });
    }
    else {
      setHWData({ ...HWData, deliverableOnly : true, current : screenData() });
    }
  };
  
  const goHome = () => {
    setHWData({ ...HWData, chosen : "combined", current : HWData.combined, page : "home", deliverableOnly : false, search: "" });
  };
  
  const goPeople = () => {
    if (HWData.deliverableOnly) {
      setHWData({ ...HWData, chosen : "people", current : screenSet(HWData.people), page : "people", search: "" });
    }
    else {
      setHWData({ ...HWData, chosen : "people", current : HWData.people, page : "people", search: "" });
    }
  };
  
  const goGroups = () => {
    if (HWData.deliverableOnly) {
      setHWData({ ...HWData, chosen : "groups", current : screenSet(HWData.groups), page : "groups", search: "" });
    }
    else {
      setHWData({ ...HWData, chosen : "groups", current : HWData.groups, page : "groups", search: "" });
    }
  };
  
  const doSearch = (event) => {
    const phrase = event.target.value.toLowerCase();
    let filtered = HWData.current;
    if (phrase.length >= 2) {
      filtered = HWData[HWData.chosen].reduce((prev, curr) => {
        if (curr.address_1.toLowerCase().includes(phrase)) { return [...prev, curr]; }
        else if (curr.address_2.toLowerCase().includes(phrase)) { return [...prev, curr]; }
        else if (curr.city.toLowerCase().includes(phrase)) { return [...prev, curr]; }
        else if (curr.name.toLowerCase().includes(phrase)) { return [...prev, curr]; }
        else if (curr.state.toLowerCase().includes(phrase)) { return [...prev, curr]; }
        else { return prev; }
      }, []);
    }
    if (HWData.deliverableOnly) { filtered = screenSet(filtered); }
    setHWData({ ...HWData, search: phrase, current: filtered });
  };
  
  const exportToCsv = (event) => {
    event.preventDefault();
    const processed = HWData.current.map((entry) => {
      return [entry.name, entry.address_1, entry.address_2, entry.city, entry.state, entry.zip ].map((field) => {
        return '"' + String(field).replace(/"/g, '""') + '"';
      });
    });
    const csv = processed.reduce((prev, curr) => {
      return prev + curr.join(',') + "\n";
    }, "");
    
    const filename = "heartwood_data.csv";
    
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }    
  };
  
  const newId = () => {
    const id   = v4();
    const used = HWData.combined.reduce((prev, curr) => (prev || curr.id === id), false);
    if (used) {
      return newId();
    }
    else {
      return id;
    }
  };
  
  const goNew = (event) => {
    event.preventDefault();
    setHWData({ ...HWData, page: "newContact", search: "", newContact: {
      'id' : newId(),
      'type' : 'person',
      'deliverable' : true,
      'name' : "",
      'address_1' : "",
      'address_2' : "",
      'city' : "",
      'state' : "",
      'zip' : "",
      'email' : "",
      'phone' : ""
    }});
  };

  return (
    <>
      <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow" id="headerBar">
        <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="/#">Heartwood</a>
        <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" value={HWData.search} onChange={doSearch} />
        <div className="navbar-nav">
          <div className="nav-item text-nowrap">
            <a className="nav-link px-3" href="/#">Sign out</a>
          </div>
        </div>
      </header>
      <div className="container-fluid">
        <div className="row">
          <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="position-sticky pt-3">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <a className={('home' === HWData.page) ? 'nav-link active' : 'nav-link'} href="/#" onClick={goHome}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home" aria-hidden="true"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className={('people' === HWData.page) ? 'nav-link active' : 'nav-link'} href="/#" onClick={goPeople}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="grey" viewBox="0 0 16 16"><path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/><path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/></svg>
                    &nbsp;People Only
                  </a>
                </li>
                <li className="nav-item">
                  <a className={('groups' === HWData.page) ? 'nav-link active' : 'nav-link'} href="/#" onClick={goGroups}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users" aria-hidden="true"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    Groups Only
                  </a>
                </li>
                <li className="nav-item">
                  <a className={('add' === HWData.page) ? 'nav-link active' : 'nav-link'} href="/#" onClick={goNew}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="grey" viewBox="0 0 16 16"><path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/><path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/></svg>
                    &nbsp;Add New Entry
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom" id="innerHeading">
              <h1 className="h2">Heartwood Database</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  <button type="button" className="btn btn-sm btn-outline-secondary" onClick={deliverable}>
                  {HWData.deliverableOnly ? "Show All Entries" : "Show Deliverable Addresses Only"}
                  </button>
                  <button type="button" className="btn btn-sm btn-outline-secondary" onClick={exportToCsv}>Export</button>
                </div>
              </div>
            </div>

            <Focus />
          
          </main>
        </div>
      </div>
    </>
  );

}

export default Home;
