import { useHWAppData } from "./HWAppDataProvider";

function Edit() {
  const { HWData, setHWData } = useHWAppData();
  
  const update = (event) => {
    const key = event.target.getAttribute('name');
    const val = event.target.value;
    let temp  = HWData.current;
    temp[HWData.edit][key] = val;
    setHWData({ ...HWData, current: temp });
  };
  
  const deliverableUpdate = (event) => {
    let temp  = HWData.current;
    temp[HWData.edit]['deliverable'] = event.target.checked;
    setHWData({ ...HWData, current: temp });
  };
  
  const cancel = (event) => {
    setHWData({ ...HWData, page: "home", edit: null, search: "" });
  };
  
  const saveChanges = (event) => {
    event.preventDefault();
    
    // set screen to "laoding" gif
    setHWData({ ...HWData, page: "home", search: "", loading: true});
    
    fetch( "https://aoufternk0.execute-api.us-east-1.amazonaws.com/0/members", {
      method : "PUT",
      headers : {
        Authorization : HWData.token
      },
      body: JSON.stringify({
        email : HWData.userEmail,
        id : HWData.current[HWData.edit].id,
        update : HWData.current[HWData.edit]
      })
    })
    .then((res) => {
      if (401 === res.status) {
        alert("You're authentication has expired. Please login again.");
        setHWData({page: "login", token: null, userEmail: "", userPassword : "", loading: false});
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          setHWData({
            ...HWData, 
            combined : [
              ...data.people,
              ...data.groups
            ],
            people : data.people,
            groups : data.groups, 
            current : [
              ...data.people,
              ...data.groups
            ],
            deliverableOnly : false,
            chosen : "combined",
            loading : false,
            page : "home",
            search: "",
            donations : data.donations
          }); 
        })
        .catch((err) => {
          console.log(err);
        })        
      }
    })
    .catch((err) => {
      console.log(err);
    });      
  };
  
  return (
    <>
      <div className="row">
        <div className="col d-md-flex justify-content-md-end d-grid gap-2">
          <button className="btn btn-outline-secondary btn-sm" onClick={cancel} type="button">
            Cancel
          </button>
          <button className="btn btn-outline-success btn-sm" onClick={saveChanges} type="button">
            Save Changes
          </button>
        </div>
      </div>   
    
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Name: 
        </div>    
        <div className="col-md-10">
          <input type="text" name="name" value={HWData.current[HWData.edit]['name']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Address:
        </div>
        <div className="col-md-10">
          <input type="text" name="address_1" value={HWData.current[HWData.edit]['address_1']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-10 offset-md-2">
          <input type="text" name="address_2" value={HWData.current[HWData.edit]['address_2']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          City:
        </div>
        <div className="col-md-10">
          <input type="text" name="city" value={HWData.current[HWData.edit]['city']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          State:
        </div>
        <div className="col-md-10">
          <input type="text" name="state" value={HWData.current[HWData.edit]['state']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Zip Code:
        </div>
        <div className="col-md-10">
          <input type="text" name="zip" value={HWData.current[HWData.edit]['zip']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Email:
        </div>
        <div className="col-md-10">
          <input type="text" name="email" value={HWData.current[HWData.edit]['email']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Phone:
        </div>
        <div className="col-md-10">
          <input type="text" name="phone" value={HWData.current[HWData.edit]['phone']}
          className="form-control" onChange={update} />
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Type of Entry:
        </div>
        <div className="col-md-10">
          <input type="radio" name="type" value="person" onChange={update}
          checked={"person" === HWData.current[HWData.edit]['type']} 
          style={{"marginRight":"5px"}} /> Person
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-10 offset-md-2">
          <input type="radio" name="type" value="group" onChange={update}
          checked={"group" === HWData.current[HWData.edit]['type']}
          style={{"marginRight":"5px"}} /> Group
        </div>
      </div>
      
      <div className="row" style={{"marginTop":"10px"}}>
        <div className="col-md-2">
          Deliverable Address? 
        </div>
        <div className="col-md-10">
          <input type="checkbox" name="deliverable" value="1" onChange={deliverableUpdate}
          checked={HWData.current[HWData.edit]['deliverable']} />
        </div>
      </div>

      <div className="row"  style={{"marginTop":"10px","marginBottom":"50px"}}>
        <div className="col d-md-flex justify-content-md-end d-grid gap-2">
          <button className="btn btn-outline-secondary btn-sm" onClick={cancel} type="button">
            Cancel
          </button>
          <button className="btn btn-outline-success btn-sm" onClick={saveChanges} type="button">
            Save Changes
          </button>
        </div>
      </div>   
    </>    
  );
}

export default Edit;